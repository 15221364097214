import React, { useState } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";
import education from "../../assets/Resume/education.svg";
import workHistory from "../../assets/Resume/work-history.svg";
import programming from "../../assets/Resume/programming-skills.svg";
import projects from "../../assets/Resume/projects.svg";
import interests from "../../assets/Resume/interests.svg";
export default function Resume(props) {
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffSetStyle, setCarousalOffSetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // const fadeInSubscription = //turn on later
  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span className="bullet-heading">
            {props.heading ? props.heading : ""}
          </span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  const resumeBullets = [
    { label: "Education", logoSrc: education },
    { label: "Work History", logoSrc: workHistory },
    {
      label: "Programming Skills",
      logoSrc: programming,
    },
    { label: "Projects", logoSrc: projects },
    { label: "Interests", logoSrc: interests },
  ];

  const programmingSkillsDetails = [
    { skill: "Typescript", ratingPercentage: 85 },
    { skill: "JavaScript", ratingPercentage: 85 },
    { skill: "NextJS", ratingPercentage: 85 },
    { skill: "SQL", ratingPercentage: 85 },
    { skill: "React JS", ratingPercentage: 85 },
    // { skill: "React Native", ratingPercentage: 85 },
    { skill: "Tailwind UI", ratingPercentage: 85 },
    { skill: "Express JS", ratingPercentage: 85 },
    { skill: "Node JS", ratingPercentage: 85 },
    { skill: "Mongo DB", ratingPercentage: 85 },
    { skill: "PostgreSQL", ratingPercentage: 85 },
    { skill: "SASS / SCSS ", ratingPercentage: 70 },
    { skill: "HTML", ratingPercentage: 85 },
    { skill: "CSS", ratingPercentage: 85 },
  ];

  const projectDetails = [
    // {
    //   title: 'Personal Portfolio Website',
    //   duration: { fromDate: 'Sept. 2021', toDate: 'Oct. 2021' },
    //   description:
    //     'A personal Portfolio website to showcase my details and projects',
    //   subHeading: ' Technologies used: React, Node, and RxJS',
    // },
    {
      title: "Code-Warriors",
      duration: { fromDate: "Aug. 2021", toDate: "Sept. 2021" },
      description:
        "Interactive multiplayer game where players can challenge each other to a round of solving algorithms live.",
      subHeading:
        "Technologies used: MERN Stack, React, Redux, Node, MongoDB, Mongoose Express, Socket.IO, and Material UI",
    },
    {
      title: "Ray's Kitchen",
      duration: { fromDate: "Aug.2021", toDate: "Aug.2021" },
      description:
        "E-Commerce website where customers can order delicious food and have delivered straight to their doorsteps",
      subHeading:
        "Technologies used: PERN Stack, React, Redux, Express Node.js, PostgreSQL, Material UI, and Bootstrap",
    },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"Waubonsee College"}
        subHeading={"Computer Science"}
        fromDate={"Graduation Date"}
        toDate={"2024"}
      />
      <ResumeHeading
        heading={"University of Memphis"}
        subHeading={"International Business minor Japanese language"}
        fromDate={"2000"}
        toDate={"2005"}
      />

      <ResumeHeading
        heading={"Fullstack Academy of Code"}
        subHeading={"Fullstack Software Engineering and Web Development"}
        fromDate={"2021"}
        toDate={" 2021"}
      />
    </div>,
    //work experience
    <div className="resume-screen-container" key="work-experience">
      <ResumeHeading
        heading={"Ricoma International"}
        subHeading={"Software Engineer"}
        fromDate={"2022"}
        toDate={"Present"}
      />
      <div className="experience-description">
        <span className="resume-description-text">
          JavaScript | mySQL | PHP | Typescript | Vite | Vue
        </span>
        <br />
        <div className="experience-description">
          {/* <span className="resume-description-text">
            Develop or upgrade websites/web applications for small to medium
            scale businesses.
          </span> */}
          <br />
        </div>
      </div>
      <br />
      <ResumeHeading
        heading={"Freelance"}
        subHeading={"Fullstack Software Engineer"}
        fromDate={"2021"}
        toDate={"Present"}
      />
      <div className="experience-description">
        <span className="resume-description-text">
          JavaScript | React | SQL | mySQL| NextJS | React | Mobile Application
          Development
        </span>
        <br />
        <div className="experience-description">
          {/* <span className="resume-description-text">
            Develop or upgrade websites/web applications for small to medium
            scale businesses.
          </span> */}
          <br />
        </div>
      </div>
      <br />
      <ResumeHeading
        heading={"K.T. Billing"}
        subHeading={"DBA/IT Support"}
        fromDate={"June 2016"}
        toDate={"2022"}
      />
      <div className="experience-description">
        {/* <span className="resume-description-text">
          Develop and maintain IT technology for rapid billing logistics within
          the healthcare industry
        </span> */}
      </div>
      {/* <div className="experience-description">
        <span className="resume-description-text">
       
        </span>
      
      </div> */}
    </div>,
    //programming skills
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          {/* <div className="heading-bullet"></div> */}
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,
    <div className="resume-screen-container" key="projects">
      {projectDetails.map((projectDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectDetails.title}
          subHeading={projectDetails.subHeading}
          description={projectDetails.description}
          fromDate={projectDetails.duration.fromDate}
          toDate={projectDetails.duration.toDate}
        />
      ))}
    </div>,
    <div className="resume-screen-container" key="interests">
      <ResumeHeading
        heading={"Martial Artist"}
        description={"Taekwondo Instructor Intern and Hapkido Martial Arts"}
      />
      <ResumeHeading
        heading={"Cooking"}
        description={
          "I'm very big on grilling especially smoking ribs Memphis style"
        }
      />
    </div>,
  ];
  //carousel
  const handleCarousel = (index) => {
    let offsetHeight = 360;
    let newCarouselOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };
    setCarousalOffSetStyle(newCarouselOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousel(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img className="bullet-logo" src={`${bullet.logoSrc}`} alt="B" />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreen = () => {
    return (
      <div
        style={carousalOffSetStyle.style}
        className="resume-details-carousel"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullets-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreen()}</div>
        </div>
      </div>
    </div>
  );
}
